import { Component } from '@angular/core';

import { StartUpShipsComponent } from '../start-up-ships/start-up-ships.component';

import { ShipClass } from '../../interfaces/ship-class';

@Component({
  selector: 'start-up-freighters',
  templateUrl: '../start-up-ships/start-up-ships.component.html',
})
export class StartUpFreightersComponent extends StartUpShipsComponent {
  override startUpTitle: string = 'Freighters';

  constructor () {
    super();
    this.transactionType = 'Freighters';
  }

  override filter(shipClass: ShipClass) {
    let freighterFlag = shipClass.freighterFlag || 0;
    return (shipClass.hullClass.indexOf('FT') > -1) || (freighterFlag === 1);
  };
}
