<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col">
  <ng-container edit-body>
    <div class="modal-center flex flex-col">
      <gray-label-wrapper [fieldLabel]="'Quantity'" [fieldName]="'quantity'" class="w-full text-black flex items-center">
        <input id="quantity" class="m-2 p-2 form-input ism-black" type="number" min="1" max="99" [(ngModel)]="transaction.quantity" (change)="calculateTotal()" required />
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Ship Class'" [fieldName]="'shipclass'" class="w-full text-black flex items-center">
        <select id="shipClass" class="m-2 p-2 w-3/5 form-select ism-black" [(ngModel)]="shipClass" (change)="calculateTotal()">
          @for (shipClass of shipClasses(); track shipClass) {
            <option [ngValue]="shipClass">{{ shipClass.className }} ({{ shipClass.hullClass }})</option>
          }
        </select>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Fleet Name'" [fieldName]="'fleet'" class="w-full text-black flex items-center">
        <select id="fleet" class="m-2 p-2 w-3/5 form-select ism-black" [(ngModel)]="fleet" (change)="calculateTotal()">
          @for (fleet of fleets(); track fleet) {
            <option [ngValue]="fleet">{{ fleet.fleetName }}</option>
          }
        </select>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Cost Each'" [fieldName]="'buildCost'" class="w-full text-black flex items-center">
        <span class="m-2 p-2 ism-black">{{ shipClass?.buildCost }}</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Total'" [fieldName]="'total'" class="w-full text-black flex items-center">
        <span class="m-2 p-2 ism-black">{{ transaction.amount }}</span>
      </gray-label-wrapper>
    </div>
  </ng-container>
</edit-dialog>
