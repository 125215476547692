import { Component } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'app-population-transaction-edit',
  templateUrl: './population-transaction-edit.component.html'
})
export class PopulationTransactionEditComponent extends DialogHandlerComponent {
  title: string = 'Start Up Population Emplacement Editor';
  bodyData: { [key: string]: any; } = {};
  transaction: Transaction;
  maxPtu: number;

  constructor () {
    super();
    this.bodyData = this.data['bodyData'];
    this.transaction = this.data.document as Transaction;
    this.maxPtu = this.bodyData['maxPu'] - (this.bodyData['pu'] || 0);
  };

  calculateTotal () {
    if (this.transaction.quantity >= 0) {
      this.transaction.amount = this.transaction.quantity * this.bodyData['cost'];
    }
  };

  override save () {
    this.dialogRef.close({
      documentName: "Transaction",
      document: this.transaction
    });
  };
};
