import { Component, OnInit } from '@angular/core';

import { Transaction } from '../../interfaces/transaction';

import { TroopsBaseComponent } from '../../economics/troops-base/troops-base.component';
import { TroopTransactionEditComponent } from '../../economics/troop-transaction-edit/troop-transaction-edit.component';

@Component({
  selector: 'troops',
  templateUrl: './troops.component.html',
})
export class TroopsComponent extends TroopsBaseComponent {
  override pageTitle: string = "Planetary Control Forces (PCF) - Start Up";
  override componentType = TroopTransactionEditComponent;

  constructor () {
    super();
    this.readOnly = this.race().readyForEconomicsTurn >= this.race().turnActivated;
  };
};
