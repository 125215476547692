<div id="_Startup-Population_" class="flex flex-col iBTTiny:mx-auto md:m-0 iBTTiny:w-11/12 md:w-full">
  <div class="p-2 ism-gold text-1.5">{{ pageTitle }}</div>
  <table class="w-full relative border">
    <thead class="z-30 sticky top-[3.5rem] bg-black">
      <tr>
        <th id="_thead-top-border_" class="p-0 h-[2px] sticky top-[3px] bg-white" colspan="11"></th>
      </tr>
      <tr>
        <th data-tooltip="Location ID" class="p-2 text-left bg-black border-y-0 ism-gold tooltip-tr">Locator</th>
        <th data-tooltip="Population Unit" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">PU</th>
        <th data-tooltip="Population Transport" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">PTU</th>
        <th data-tooltip="Maximum PU" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">Max PU</th>
        <th data-tooltip="Habitability Defferential" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">HD</th>
        <th data-tooltip="Industrial Unit" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">IU</th>
        <th data-tooltip="Multiplier" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">Multiplier</th>
        <th data-tooltip="Resource Exploitation Index" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">REI</th>
        <th data-tooltip="More PTU" class="p-2 text-center bg-black border-y-0 ism-gold tooltip-tr">Add'l PTU</th>
        <th data-tooltip="Cost" class="p-2 text-right bg-black border-y-0 ism-gold tooltip-tl">Cost</th>
        @if (!readOnly) {
          <th class="p-2 bg-black border-y-0">Action</th>
        }
      </tr>
      <tr>
        <th id="_thead-bottom-border_" class="p-0 h-[2px] sticky top-[3px] bg-white" colspan="11"></th>
      </tr>
    </thead>
    <tbody>
      @for (planetData of bodyData() | orderBy: "locator"; track $index) {
        <tr class="data-odd-light">
          <td data-tooltip="Loation ID" class="tooltip-tr">{{ planetData.locator }}</td>
          <td data-tooltip="PU - (Population Unit)" class="text-right tooltip-tr">{{ planetData.pu || 0 | number: "1.0-0" }}</td>
          <td data-tooltip="PTU - (Population Transport Unit)" class="text-right tooltip-tr">{{ planetData.ptu || 0 | number: "1.0-0" }}</td>
          <td data-tooltip="Maxium PU - (Population Unit)" class="text-right tooltip-tr">{{ planetData.maxPu || 0 | number: "1.0-0" }}</td>
          <td data-tooltip="HD - (Habitability Differential)" class="text-right tooltip-tr">{{ planetData.habitDifference | number: "1.0-0" }}</td>
          <td data-tooltip="IU - (Industrial Unit)" class="text-right tooltip-tr">{{ planetData.iu || 0 }}</td>
          <td data-tooltip="Multiplier" class="text-center tooltip-tr">{{ planetData.multiplier }}</td>
          <td data-tooltip="REI - (Resource Exploitation Index)" class="text-center tooltip-tr">{{ planetData.rei }}</td>
          <td data-tooltip="More PTU - (Population Transport Unit)" class="text-center tooltip-tr">{{ planetData.quantity || 0 }}</td>
          <td data-tooltip="Cost" class="text-right tooltip-tl">{{ planetData.amount || 0 | number: "1.0-0" }} MC</td>

          @if (!readOnly) {
            <td class="text-center">
              @if ((planetData.pu || 0) > 0 && (planetData.pu || 0) === (planetData.maxPu || 0)) {
                Max PU
              } @else if ((planetData.pu || 0) < (planetData.maxPu || 0)) {
                <div id="_svgPencil-EDIT" class="mx-2 flex items-center justify-center">
                  <a type="button" (click)="editTransaction(planetData.bodyId, planetData.transactionId)">
                    @if (planetData.quantity > 0) {
                      <ism-svg data-tooltip="Edit Population" class="tooltip-tl" [name]="'pencilSquare'"></ism-svg>
                    } @else {
                      <ism-svg data-tooltip="Add Population" class="tooltip-tl" [name]="'plus'"></ism-svg>
                    }
                  </a>
                  @if (planetData.quantity > 0) {
                    <a type="button" (click)="openDeleteTransaction(planetData.transactionId)" data-text="Delete">
                      <ism-svg data-tooltip="Remove Population" class="tooltip-tl" [name]="'trashcan'"></ism-svg>
                    </a>
                  }
                </div>
              }
            </td>
          }
        </tr>
      }
      <tr class="data-odd-light">
        <td class="text-right font-bold ism-gold" colspan="9">Total:</td>
        <td class="text-right font-bold ism-gold">{{ total() || 0 | number: "1.0-0" }} MC</td>
        @if (!readOnly) {
          <td class="text-center"></td>
        }
      </tr>
    </tbody>
  </table>
</div>
