import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated } from '../auth/auth.guard';

import { StartUpDefensesComponent } from './defenses/defenses.component';
import { StartUpFreightersComponent } from './freighters/freighters.component';
import { PopulationComponent } from './population/population.component';
import { StartUpSummaryComponent } from './start-up-summary/start-up-summary.component';
import { TroopsComponent } from './troops/troops.component';
import { StartUpWarshipsComponent } from './warships/warships.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticated],
    children: [
      {
        path: 'suSummary',
        component: StartUpSummaryComponent,
      },
      {
        path: 'warships',
        component: StartUpWarshipsComponent,
      },
      {
        path: 'defenses',
        component: StartUpDefensesComponent,
      },
      {
        path: 'freighters',
        component: StartUpFreightersComponent,
      },
      {
        path: 'population',
        component: PopulationComponent,
      },
      {
        path: 'troops',
        component: TroopsComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StartUpEconomicsRoutingModule { }
