<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col">
  <ng-container edit-body>
    <div class="modal-center flex flex-col">
      <gray-label-wrapper [fieldLabel]="'Quantity'" [fieldName]="'quantity'" class="w-full text-black flex items-center">
        <input id="quantity" class="m-2 p-2 w-3/5 form-input ism-black" type="number" min="0" max="{{ bodyData['maxPtu'] }}" [(ngModel)]="transaction.quantity" (change)="calculateTotal()" required />
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Cost Each'" [fieldName]="'cost'" class="w-full text-black flex items-center">
        <span class="m-2 p-2 text-center ism-black">{{ bodyData["cost"] }}</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Total'" [fieldName]="'total'" class="w-full text-black flex items-center">
        <span class="m-2 p-2 font-bold text-center text-black">{{ transaction.amount }}</span>
      </gray-label-wrapper>
    </div>
  </ng-container>
</edit-dialog>
