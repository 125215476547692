import { Component, Inject, Signal, signal } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { Fleet } from '../../interfaces/fleet';
import { ShipClass } from '../../interfaces/ship-class';
import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'app-start-up-ship-transaction-edit',
  templateUrl: './start-up-ship-transaction-edit.component.html'
})
export class StartUpShipTransactionEditComponent extends DialogHandlerComponent {
  baseTitle: string = 'Start Up Ship Transaction Editor';
  shipCategory: string = 'Undetermined';
  title: string;
  transaction: Transaction;
  shipClasses: Signal<ShipClass[]>;
  fleets: Signal<Fleet[]>;
  shipClass: ShipClass;
  fleet: Fleet;

  constructor () {
    super();
    this.shipCategory = this.data['shipCategory'];
    this.shipClasses = signal<ShipClass[]>(this.data['shipClasses']);
    this.fleets = signal<Fleet[]>(this.data['fleets']);

    this.transaction = Object.assign({}, this.data.document as Transaction);
    if (this.transaction.shipClassId) {
      this.shipClass = this.shipClasses().find(shipClass => shipClass._id === this.transaction.shipClassId);
    }
    if (this.transaction.fleetId) {
      this.fleet = this.fleets().find(fleet => fleet._id === this.transaction.fleetId);
    }

    this.title = this.baseTitle + ' - ' + this.shipCategory;
  };

  calculateTotal () {
    if (this.transaction.quantity && this.shipClass && this.fleet) {
      this.transaction.shipClassId = this.shipClass._id;
      this.transaction.fleetId = this.fleet._id;
      this.transaction.amount = this.transaction.quantity * this.shipClass.buildCost;
    }
  };

  override save () {
    this.dialogRef.close({
      documentName: "Transaction",
      document: this.transaction
    });
  };
}
