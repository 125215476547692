import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EconomicsModule } from '../economics/economics.module';
import { SharedModule } from '../shared/shared.module';
import { StartUpEconomicsRoutingModule } from './start-up-economics-routing.module';

import { StartUpDefensesComponent } from './defenses/defenses.component';
import { StartUpFreightersComponent } from './freighters/freighters.component';
import { PopulationComponent } from './population/population.component';
import { StartUpSummaryComponent } from './start-up-summary/start-up-summary.component';
import { TroopsComponent } from './troops/troops.component';
import { StartUpWarshipsComponent } from './warships/warships.component';
import { StartUpShipTransactionEditComponent } from './start-up-ship-transaction-edit/start-up-ship-transaction-edit.component';
import { PopulationTransactionEditComponent } from './population-transaction-edit/population-transaction-edit.component';
import { QuickHtmlModule } from "../quick-html/quick-html.module";

@NgModule({
  declarations: [
    StartUpDefensesComponent,
    StartUpFreightersComponent,
    PopulationComponent,
    StartUpSummaryComponent,
    TroopsComponent,
    StartUpWarshipsComponent,
    StartUpShipTransactionEditComponent,
    PopulationTransactionEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    EconomicsModule,
    QuickHtmlModule,
    SharedModule,
    StartUpEconomicsRoutingModule
  ]
})
export class StartUpEconomicsModule { }
