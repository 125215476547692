<div id="_Startup-Economics-Summary_" class="py-2 w-full flex-col flex-wrap box-border">
  <div class="py-2 text-1.5">Startup Economics Summary</div>
  <turn-selector id="turnSelector" [firstTurn]="race().turnActivated" [campaignTurn]="campaign().turn" (onTurnChange)="updateEconomicTurn($event)"></turn-selector>

  <div class="w-3/4 flex flex-1 flex-col">
    <!-- //SECTION - The FIRST Turn -->
    @if (this.summarizedTransactions(); as summarizedTransactions) {
      @if (race().techLevel > 0) {
        <div id="_FIRST-TURN_" class="zeroFootprint">
          @if (!validSy) {
            <div class="alert alert-danger">Not using required number of systems.</div>
          }
          @if (!isPlayerRace) {
            <p>This race has been given {{ freeSyTransaction?.quantity || 0 | number: "1.0-0" }} {{ project()?.description || "???" }}, by credit in Defenses.</p>
            <p class="mb-10">This race is restricted to no more than {{ race().maxX || 0 }} X systems.</p>
          }
          @if (!validX) {
            <div class="alert alert-danger">Exceeding number of permitted X systems.</div>
          }
          <!-- Financial Data Table -->
          <table class="my-2 py-2 border-0">
            <tr class="data">
              <!-- //ANCHOR - INCOME & BUDGET -->
              <td class="text-left border-1">Startup Income:</td>
              <td class="text-right border-1">{{ (summarizedTransactions["popIncomeTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-center font-bold bg-gray-100 border-1 bg-opacity-20" colspan="2">Budget</td>
              <td></td>
            </tr>
            <tr class="data">
              <!-- //ANCHOR - EXPENSES -->
              <td class="text-left font-bold ism-green">Expenses</td>
              <td class="text-right font-bold ism-green">Actual</td>
              <td class="text-right font-bold ism-green">Target Percent</td>
              <td class="text-right font-bold ism-green">Target MC</td>
              @if (isPlayerRace) {
                <td></td>
              } @else {
                <td>Validity <br />(+/- 10%)</td>
              }
            </tr>

            <!-- //ANCHOR - Warships -->
            <tr class="data">
              <td class="text-left">
                <a [routerLink]="['/race', race()._id, 'economics', 'startup', 'warships']" routerLinkActive="ngActiveLinkGreen">WarShips:</a>
              </td>
              <td class="text-right" [ngClass]="{ 'text-error-400': !validWarships }">{{ (summarizedTransactions["WarshipsTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-right">{{ government()?.warships | number: "1.1-1" }}%</td>
              <td class="text-right">{{ warshipAllocation | number: "1.1-1" }} MC</td>
              <td class="text-center align-middle border">
                @if (!isPlayerRace) {
                  @if (validWarships) {
                    <img src="/assets/_icons/checkmark.png" alt="OK" height="40" width="40" />
                  } @else {
                    <img src="/assets/_icons/Xmark.png" alt="Invalid" height="40" width="40" />
                  }
                }
              </td>
            </tr>

            <!-- //ANCHOR - Defenses -->
            <tr class="data">
              <td class="text-left ism-gold">
                <a [routerLink]="['/race', race()._id, 'economics', 'startup', 'defenses']" routerLinkActive="ngActiveLinkGreen">Defenses:</a>
              </td>
              <td class="text-right" [ngClass]="{ 'text-error-400': !validDefenses }">{{ (summarizedTransactions["DefensesTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-right">{{ government()?.defense | number: "1.1-1" }}%</td>
              <td class="text-right">{{ defenseAllocation | number: "1.1-1" }} MC</td>
              <td>
                @if (!isPlayerRace) {
                  @if (validDefenses) {
                    <img src="/assets/_icons/checkmark.png" alt="OK" height="40" width="40" />
                  } @else {
                    <img src="/assets/_icons/Xmark.png" alt="Invalid" height="40" width="40" />
                  }
                }
              </td>
            </tr>

            <!-- //ANCHOR - Freighters -->
            <tr class="data">
              <td class="text-left">
                <a [routerLink]="['/race', race()._id, 'economics', 'startup', 'freighters']" routerLinkActive="ngActiveLinkGreen">Freighters:</a>
              </td>
              <td class="text-right" [ngClass]="{ 'text-error-400': !validFreighters }">{{ (summarizedTransactions["FreightersTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-right" rowspan="2">{{ government()?.freighters || 0 | number: "1.1-1" }}%</td>
              <td class="text-right" rowspan="2">{{ freighterAllocation | number: "1.1-1" }} MC</td>
              <td class="align-middle bg-cover overflow-hidden" rowspan="2">
                @if (!isPlayerRace) {
                  @if (validFreighters) {
                    <img src="/assets/_icons/checkmark.png" alt="OK" height="40" width="40" />
                  } @else {
                    <img src="/assets/_icons/Xmark.png" alt="Invalid" height="40" width="40" />
                  }
                }
              </td>
            </tr>

            <!-- //ANCHOR - Population -->
            <tr class="data">
              <td class="text-left">
                <a [routerLink]="['/race', race()._id, 'economics', 'startup', 'population']" routerLinkActive="ngActiveLinkGreen">Population:</a>
              </td>
              <td class="text-right" [ngClass]="{ 'text-error-400': !validFreighters }">{{ (summarizedTransactions["PopulationTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <!-- next 3 td elements covered by prior row with rowspan -->
            </tr>

            <!-- //ANCHOR - Troops -->
            <tr>
              <td class="text-left">
                <a [routerLink]="['/race', race()._id, 'economics', 'startup', 'troops']" routerLinkActive="ngActiveLinkGreen">Troops (PCF):</a>
              </td>
              <td class="text-right">{{ (summarizedTransactions["troopsTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-center">--</td>
              <td class="text-center">--</td>
              <td class="text-center">
                @if (!isPlayerRace) {
                  --
                }
              </td>
            </tr>

            <!-- //ANCHOR - Industrial Units -->
            <tr class="data">
              <td class="text-left ism-gold">Industrial Units:</td>
              <td class="text-right">{{ (summarizedTransactions["industrialTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-right">{{ government()?.industrial | number: "1.0-0" }}%</td>
              <td class="text-right">{{ (summarizedTransactions["industrialTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-center"></td>
            </tr>

            <!-- //!SECTION -->
            <!-- //ANCHOR - SUBTOTAL -->
            <tr class="data">
              <td class="text-left uppercase">Subtotal Expenses:</td>
              <td class="text-right" [ngClass]="{ 'text-error-400': !validFinalBalance }">{{ (summarizedTransactions["GrandTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
              <td colspan="2"></td>
              <td class="text-center bg-cover overflow-hidden">
                @if (validFinalBalance) {
                  <img src="/assets/_icons/checkmark.png" alt="OK" height="40" width="40" />
                } @else {
                  <img src="/assets/_icons/Xmark.png" alt="Invalid" height="40" width="40" />
                }
              </td>
            </tr>

            <!-- //ANCHOR - REMAINDER -->
            <tr class="italic data">
              <td class="text-left text-uppercase">Remainder:</td>
              <td class="text-right">{{ (summarizedTransactions["GrandTotal"] || {})["remainder"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-center" colspan="3">
                @if (validFinalSubmit) {
                  <button class="btn btn-default" type="button" (click)="validateStartUp()">Validate and Submit</button>
                }
                @if (userCanChangeMind) {
                  <span>
                    Validated
                    <button class="btn btn-default" type="button" (click)="changedMyMind()">Wait, I changed my mind!</button>
                  </span>
                }
              </td>
            </tr>
            @if (canFinalize) {
              <tr class="data">
                <td colspan="2"></td>
                <td colspan="3">
                  <button class="btn btn-default" type="button" (click)="finalizeStartUp()">Complete Startup</button>
                </td>
              </tr>
            }
          </table>
        </div>
      }

      @if (race().techLevel < 1) {
        <div class="zeroFootprint">
          <table>
            @if (race().techLevel === 0) {
              <tr>
                <td class="text-left">Startup Income</td>
                <td class="text-right">{{ (summarizedTransactions["popIncomeTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
                <td colspan="2">Only IND-2 receive cash (4*GPV) to be spent on SS.</td>
              </tr>
              <tr>
                <td class="text-left">
                  <a [routerLink]="['/race', race()._id, 'economics', 'startup', 'defenses']" routerLinkActive="ngActiveLinkGreen">Space Stations:</a>
                </td>
                <td class="text-right" [ngClass]="{ 'text-error-400': !validDefenses }">{{ (summarizedTransactions["DefensesTotal"] || {})["amount"] || 0 | number: "1.1-1" }} MC</td>
                <td colspan="2" class="text-center bg-cover overflow-hidden">
                  @if (!isPlayerRace) {
                    @if (validDefenses) {
                      <img src="/assets/_icons/checkmark.png" alt="OK" height="40" width="40" />
                    } @else {
                      <img src="/assets/_icons/Xmark.png" alt="Invalid" height="40" width="40" />
                    }
                  }
                </td>
              </tr>
            }
            <tr>
              <td class="text-left">{{ race().freePcf || 0 }}</td>
              <td>
                @if (race().techLevel === -2) {
                  PCF-P
                } @else if (race().techLevel === -1) {
                  PCF-I1
                } @else {
                  PCF-I2
                }
              </td>
              <td colspan="2">Non-HT races receive a number of PCFs equal to their PU.</td>
            </tr>
            @if (race().techLevel > -2) {
              <tr>
                <td class="text-left">{{ infoPopPrimary()?.iu }}</td>
                <td>IU</td>
                <td colspan="2">
                  @if (race().techLevel === -1) {
                    IND-1 races receive 25% of PU in IU.
                  } @else {
                    IND-2 races receive 50% of PU in IU.
                  }
                </td>
              </tr>
              <tr>
                <td class="text-left">Started</td>
                <td class="text-left">{{ project()?.description }}</td>
                <td colspan="2">Industrial races have paid the startup fee for the next tech level.</td>
              </tr>
            }
            <tr class="italic">
              <td class="text-left">Remainder:</td>
              <td class="text-right">{{ (summarizedTransactions["GrandTotal"] || {})["remainder"] || 0 | number: "1.1-1" }} MC</td>
              <td class="text-center" colspan="2">
                @if (validFinalSubmit) {
                  <button class="btn btn-default" type="button" (click)="validateStartUp()">Validate and Submit</button>
                }
                @if (userCanChangeMind) {
                  <span>
                    Validated
                    <button class="btn btn-default" type="button" (click)="changedMyMind()">Wait, I changed my mind!</button>
                  </span>
                }
              </td>
            </tr>
            @if (canFinalize) {
              <tr>
                <td></td>
                <td colspan="3">
                  <button class="btn btn-default" type="button" (click)="finalizeStartUp()">Complete Startup</button>
                </td>
              </tr>
            }
          </table>
        </div>
      }
    }

    @if (canReset) {
      <div class="p-8 w-3/4 flex">
        <button class="btn btn-default" type="button" (click)="rollbackStartUp()">Rollback Startup</button>
      </div>
    }
  </div>
</div>
