<div id="_Startup-Warships_" class="flex flex-col iBTTiny:mx-auto md:m-0 iBTTiny:w-11/12 md:w-full">
  <div id="_PURCHASE-LIST" class="w-full flex flex-col">
    <div class="py-2 flex items-center">
      <div class="ism-gold text-1.5">{{ startUpTitle }} Purchase List</div>
      @if (!readOnly) {
        <ism-svg data-tooltip="Add a Transaction" class="mx-4 tooltip-tr" [name]="'plus'" (click)="addTransaction()"></ism-svg>
      }
    </div>

    <div class="w-full">
      <table id="_SHIP-CLASSES" class="w-full relative border">
        <thead class="z-30 sticky top-[3.5rem] bg-black">
          <tr>
            <th id="_thead-top-border_" class="p-0 h-[2px]] top-[3px] bg-white" colspan="11"></th>
          </tr>
          <tr>
            <th data-tooltip="Ship Class" class="border bg-black ism-gold tooltip-tr">Ship Class</th>
            <th data-tooltip="Fleet" class="border bg-black ism-gold tooltip-tr">Fleet</th>
            <th data-tooltip="Cost" class="text-right border bg-black ism-gold tooltip-tr">Cost</th>
            <th data-tooltip="Quantity" class="text-right border bg-black ism-gold tooltip-tr">Quantity</th>
            <th data-tooltip="Amount" class="text-right border bg-black ism-gold tooltip-tr">Amount</th>
            <!--  -->
            @if (!readOnly) {
              <th class="text-center border bg-black ism-gold">Actions</th>
            }
          </tr>
          <tr>
            <th id="_thead-top-border_" class="p-0 h-[2px]] top-[3px] bg-white" colspan="11"></th>
          </tr>
        </thead>

        @if (shipClassHash(); as shipClassHash) {
          @if (fleetHash(); as fleetHash) {
            @for (transaction of transactions(); track transaction._id) {
              <tr class="data-odd-light">
                <!--  -->
                @if (transaction.noEdit === 0) {
                  <td data-tooltip="Ship Class" class="tooltip-tr">{{ shipClassHash[transaction.shipClassId]?.className }}</td>
                  <td data-tooltip="Fleet" class="tooltip-tr">{{ fleetHash[transaction.fleetId]?.fleetName || "Default" }}</td>
                  <td data-tooltip="Cost" class="text-right tooltip-tr">{{ shipClassHash[transaction.shipClassId]?.buildCost || 0 | number: "1.2-2" }}</td>
                }
                <td data-tooltip="Quantity" class="text-right tooltip-tr">{{ transaction.quantity }}</td>
                <!--  -->
                @if (transaction.noEdit === 1) {
                  <td data-tooltip="Description" class="text-right tooltip-tr" colspan="2">{{ transaction.description }}</td>
                }
                <td data-tooltip="Amount" class="text-right tooltip-tr">{{ transaction.amount | number: "1.2-2" }}</td>
                <!--  -->
                @if (!readOnly) {
                  <td>
                    <div id="_ICON-SET" class="flex items-center justify-center">
                      <!--  -->
                      @if (!transaction["noEdit"] || !readOnly) {
                        <ism-svg data-tooltip="Edit Transaction" class="mx-2 tooltip-tl" [name]="'pencilSquare'" (click)="editTransaction(transaction)" data-text="Edit"></ism-svg>
                        <ism-svg data-tooltip="Delete Transaction" class="mx-2 tooltip-tl" [name]="'trashcan'" (click)="deleteTransaction(transaction)"></ism-svg>
                      }
                    </div>
                  </td>
                }
              </tr>
            }
          }
        }

        <!-- TODO: [FNG-32] -->
        <tr class="data-odd-light">
          <td class="text-right font-bold ism-gold" colspan="4">Total:</td>
          <td class="text-right font-bold ism-gold">{{ total() | number: "1.2-2" }}</td>
          @if (!readOnly) {
            <td></td>
          }
        </tr>
      </table>
    </div>
  </div>
</div>
