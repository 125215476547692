import { Component } from '@angular/core';

import { StartUpShipsComponent } from '../start-up-ships/start-up-ships.component';

import { ShipClass } from '../../interfaces/ship-class';

@Component({
  selector: 'start-up-warships',
  templateUrl: '../start-up-ships/start-up-ships.component.html'
})
export class StartUpWarshipsComponent extends StartUpShipsComponent {
  override startUpTitle: string = 'Warships';

  constructor () {
    super();
    this.transactionType = 'Warships';
  };

  override filter(shipClass: ShipClass) {
    let freighterFlag = shipClass.freighterFlag || 0;
    let criteriaMet = (shipClass.hullClass.indexOf('BS') === -1) &&
      (shipClass.hullClass != 'AF') &&
      (shipClass.hullClass != 'SS') &&
      (shipClass.hullClass.indexOf('FT') === -1) &&
      (freighterFlag != 1);
    return criteriaMet;
  };
}
