import { Component } from '@angular/core';

import { StartUpShipsComponent } from '../start-up-ships/start-up-ships.component';

import { ShipClass } from '../../interfaces/ship-class';

@Component({
  selector: 'start-up-defenses',
  templateUrl: '../start-up-ships/start-up-ships.component.html',
})
export class StartUpDefensesComponent extends StartUpShipsComponent {
  override startUpTitle: string = 'Defenses';

  constructor () {
    super();
    this.transactionType = 'Defenses';
  };

  override filter(shipClass: ShipClass) {
    return (shipClass.hullClass.indexOf('BS') > -1) ||
      (shipClass.hullClass === 'AF') ||
      (shipClass.hullClass === 'SS');
  };
}
